import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsletterSubscribe from "../components/newsletterSubscribe"

export default function Newsletter(props) {
  const featuredImage = {
    fluid: getImage(props.data.wpMediaItem.localFile),
    alt: props.data.wpMediaItem.altText || "Newsletter",
  }

  return (
    <Layout>
      <div className="red-to-violet h-[200px] featured-image-wrapper">
        <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full">
          <GatsbyImage
            image={featuredImage.fluid}
            alt={featuredImage.alt}
            className="!absolute -z-20 !w-screen"
          />
          <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>
          <div
            className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
            style={{
              backgroundImage:
                "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
            }}
          ></div>
        </div>
      </div>
      <div className="red-to-violet page">
        <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-12 lg:mb-24"
            >
              Subscribe to our Newsletter
            </h1>
          </div>
          <div className="relative mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="entry-content w-full"
            >
              <NewsletterSubscribe />
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => {
  return (
    <Seo title="Sign up for our Newsletter | TransTech Social Enterprises" />
  )
}

export const query = graphql`
  {
    wpMediaItem(databaseId: { eq: 23640 }) {
      title
      altText
      localFile {
        url
        childImageSharp {
          gatsbyImageData(
            webpOptions: { quality: 90 }
            width: 1920
            formats: WEBP
            layout: FIXED
            aspectRatio: 2
          )
        }
      }
    }
  }
`
